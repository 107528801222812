import * as React from "react"
import styled from "styled-components"
import { Global, css } from "@emotion/react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Header from "../components/header"
import { StaticImage } from "gatsby-plugin-image"

const AnnualStyle = styled.section`
  font-family: "Roboto";
  font-style: normal;
  background: linear-gradient(180deg, #e3f9e5 0%, #faf9f7 54.69%);
  color: rgb(80, 74, 64);
`

const Title = styled.h1`
  margin: auto;
  text-align: center;
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 32px;
  padding: 0 3.5%;
  color: rgb(66, 61, 51);
  max-width: 1025px;
  @media (max-width: 480px) {
    font-size: 32px;
  }
`

const PriceHolder = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
`

const SubTitle = styled.h3`
  font-size: 24px;
  margin-top: 32px;
  margin-bottom: 8px;
`

const ImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
  margin-top: 32px;

  .img1 {
    width: 364px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    /* margin: 0 24px; */
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    .img1 {
      width: 40%;
      margin: 24px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    .img1 {
      width: 50%;
      margin: 24px;
    }
  }
`

const Body = styled.div`
  padding: 0 3.5%;
  max-width: 1025px !important;
  margin: auto;
  .center {
    margin-bottom: 16px;
  }
  padding-bottom: 64px;
`

const IndexPage = props => {
  return (
    <AnnualStyle>
      <Global
        styles={css`
          body {
            margin: 0;
          }
          html {
            margin: 0;
            overflow-x: hidden;
          }
        `}
      />
      <Header></Header>
      <ImageRow>
        <div className="img1">
          <StaticImage
            src="../images/projekt_dokazivanje_koncepta_kamere/NextGenerationEU_funding.png"
            layout="constrained"
          />
        </div>
      </ImageRow>
      <Title>
        Projekt: „Dokazivanje primjenjivosti neuromorfnih kamera i umjetne
        inteligencije za prediktivno održavanje ključne opreme i strojeva u
        ljevaonicama metala”
      </Title>

      <Body>
        <p>
          <b>Korisnik projekta:</b> Ascalia d.o.o. za informatičke usluge
        </p>
        <p>
          <b>Kod projekta:</b> NPOO.C3.2.R3-I1.01.0073
        </p>

        <SubTitle>Kratki opis projekta</SubTitle>

        <p>
          Projektom će se ispitati je li moguće primijeniti napredni vizijski
          sustav temeljen na neuromorfnim kamerama i algoritmima umjetne
          inteligencije za snimanje i analizu prekomjernih vibracija koje se
          javljaju u radu strojeva i opreme u ljevaonicama metala. Prikupljeni i
          obrađeni signali koristit će se kao input za softversku AI- platformu
          koja će omogućiti predviđanje kvarova opreme i strojeva koji će zatim
          biti popravljeni ili zamijenjeni. Ukoliko se novi sustav pokaže
          preciznijim od konvencionalnih senzora, postoji veliki potencijal za
          njegovu komercijalizaciju ne samo u ljevaonicama metala, već i u
          drugim pogonima gdje je potrebno prediktivno održavanje ključne opreme
          i strojeva, čime se uvelike štedi vrijeme i novac.
        </p>

        <SubTitle>Razdoblje provedbe projekta</SubTitle>

        <p>
          Projekt se provodi od 03. travnja 2024. godine do 03. travnja 2025.
          godine.
        </p>

        <SubTitle>Opći cilj projekta</SubTitle>

        <p>
          Predloženi projekt ima za cilj ispitati primjenjivost najnovije
          generacije vizijskih sustava na tržištu - neuromorfnih kamera i
          Ascalijinih algoritama umjetne inteligencije za praćenje i analizu
          prekomjernih vibracija u ekstremnim uvjetima rada ljevaonica metala.
        </p>

        <SubTitle>Aktivnosti i očekivani rezultati projekta</SubTitle>

        <p>
          <strong>
            A1: Izrada prototipa rješenja u laboratorijskom okruženju:
          </strong>
        </p>
        <p>
          - R1: Razvijen softver za prikupljanje podataka na industrijskom
          ugradbenom računalu
        </p>

        <p>
          - R2: Razvijen AI-softver za analizu i validaciju podataka, te
          predviđanje kvarova{" "}
        </p>

        <p>
          - R3: Prilagođen hardver (kamera, računalo, kablovi) za rad u
          industrijskom okruženju.
        </p>

        <p>
          <strong>A2: Testiranje i demonstracija tehničke izvedivosti:</strong>
        </p>

        <p>
          - R4: Integriran hardver, softver za prikupljanje podataka i
          AI-softver za analizu
        </p>

        <p>
          - R5: Testirane i validirane performanse integriranog sustava na
          sobnoj temperaturi{" "}
        </p>

        <p>
          - R6: Analizirani rezultati ispitivanja i demonstracije u ljevaonici
          na visokim temperaturama.
        </p>

        <p>
          <strong>
            A3: Tehno-ekonomska analiza i Zaštita intelektualnog vlasništva
          </strong>
        </p>

        <p>- R7: Provedena analiza tržišta i izrađen plan komercijalizacije</p>
        <p>
          - R8: Pripremljena dokumentacija za zaštitu intelektualnog vlasništva.{" "}
        </p>

        <p>
          <strong>A4: Upravljanje projektom i administracija </strong>
        </p>

        <p>
          - R9: Postupci nabave provedeni u skladu s UzP i pravilima za NOJN
        </p>
        <p>- R10: Projektne aktivnosti provedene u skladu s planom</p>
        <p>
          - R11: Pripremljena i usvojena narativna i financijska izvješća, te
          završno izvješće{" "}
        </p>
        <p>
          <strong>A5: Promidžba i vidljivost:</strong>
        </p>

        <p>- R12: Objavljeni podaci o projektu na web stranici Prijavitelja</p>
        <p>- R13: Sudjelovanje na jednoj konferenciji</p>
        <br />

        <PriceHolder>
          <strong>Ukupna vrijednost projekta iznosi: </strong>
          &nbsp;91.330,86 EUR<strong></strong>
        </PriceHolder>
        <PriceHolder>
          <strong>Iznos sufinanciranja sa strane Europske unije:&nbsp;</strong>
          62.561,24 EUR
        </PriceHolder>

        <p>
          <strong>
            Za više informacija o projektu možete kontaktirati Marin Bek na:{" "}
          </strong>
          <strong>
            <a href="mailto:marin@ascalia.io">marin@ascalia.io</a>
          </strong>
          <strong> </strong>
          <strong></strong>
        </p>

        <p>
          <em>
            Za više informacija o EU Pozivu iz kojeg je projekt financiran,
            posjetite stranicu:
          </em>
        </p>

        <p>
          <em>
            <u>
              <a href="https://fondovieu.gov.hr/pozivi/27">
                https://fondovieu.gov.hr
              </a>
            </u>
          </em>
          <em></em>
        </p>
      </Body>
    </AnnualStyle>
  )
}

export default IndexPage
